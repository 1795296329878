import { Facebook, Twitter, Linkedin, Instagram } from "lucide-react";

const SharePrices = () => {
  return (
    <div className="text-center">
      <h6>Share Minority Startups Price</h6>
      <div
        className={`d-flex justify-content-center align-items-center m-auto mt-4`}
        style={{ gap: "20px" }}
      >
        <Facebook style={{ cursor: "pointer" }} />
        <Twitter style={{ cursor: "pointer" }} />
        <Linkedin style={{ cursor: "pointer" }} />
        <Instagram style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
};
export default SharePrices;

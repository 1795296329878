import classNames from "classnames";
import { ArrowDownToLine } from "lucide-react";
import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getRequest, postRequest } from "../../../../api-service";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import PDFFlipBook from "./PdfFlipBook";
import noData from "../../../../images/Coin.png";
import { useTheme } from "../../../../layout/provider/Theme";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Icon } from "../../../../components/Component";

const Reports = ({ coinDetails }) => {
  const { coinId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:400px)");

  const [activeTab, setActiveTab] = useState(0);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [doc, setDoc] = useState("");
  const [open, setOpen] = useState(false);
  const [docPresent, setDocPresent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getDocumentData = async () => {
    setOpen(true);
    setLoading(true);
    setDocPresent(false);

    try {
      const payload = {
        coin_id: coinId,
        category_id: selectedCategoryId,
        sub_category_id: selectedSubCategoryId,
      };
      const res = await postRequest("/reports/get-pdf-reports", payload);
      if (res.data) {
        setDoc(res.data.count[0].report_file);
        setDocPresent(false);
      } else {
        setDoc("");
        setDocPresent(true);
      }
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error("Error fetching document data:", error);
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getRequest("/reports/get-category");
        if (response.status === true) {
          setCategories(response.data.category_data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await getRequest(`/reports/get-sub-category`);
        if (response.status === true) {
          setSubCategories(response.data.category_data);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubCategories();
  }, []);

  useEffect(() => {
    if (selectedCategoryId && selectedSubCategoryId) {
      getDocumentData();
    }
  }, [selectedCategoryId, selectedSubCategoryId]);

  const handleSubCategoryClick = (categoryId, subCategoryId) => {
    setSelectedCategoryId(categoryId);
    setSelectedSubCategoryId(subCategoryId);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {coinDetails?.map((coin) => (
        <div
          key={coin.coin_id}
          style={{
            color: theme.skin === "dark" ? "#fff" : "#000",
            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
            position: "fixed",
            minWidth: isSmallScreen ? "84%" : "90%",
            marginLeft: "-0.8%",
            fontSize: isSmallScreen ? "0.5rem" : "",
            borderBottom: "1px solid #c9bcbb",
            zIndex: 1000,
            marginTop: "-0.35%",
            // padding: "10px",
            paddingBottom: "0",
          }}
        >
          <div
            className={`${isSmallScreen ? "d-block" : "d-flex"} justify-content-start`}
            style={{
              color: theme.skin === "dark" ? "#fff" : "#000",
              fontSize: "18px",
              fontWeight: "600",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div className="d-flex">
              <img src={coin.coin_logo} alt="" height={40} width={40} />
              <p style={{ minWidth: "fit-content", fontSize: "22px", marginLeft: "10px" }}>{coin.coin_name}</p>
            </div>
            <p style={{ margin: isSmallScreen ? "-4% 21%" : "5px 1%" }}>
              <span style={{ fontSize: "14px" }}>$</span>
              {coin.price}
            </p>
          </div>
        </div>
      ))}
      <Typography variant="body2" sx={{ mb: 2 }}>
        .
      </Typography>
      <Grid container spacing={2} sx={{ minHeight: "60vh", mt: 4, pt: 2 }}>
        <Grid item xs={12} md={2.5}>
          <Box
            sx={{
              mt: -3,
              height: "100%",
              overflow: "auto",
              borderRight: "1px solid #c9bcbb",
              padding: "10px",
              position: "relative", // Adjust as needed
              zIndex: 0, // Adjust to ensure it is in the background
              backgroundColor: theme.skin === "dark" ? "#333" : "#f9f9f9",
            }}
          >
            <Sidebar
              style={{
                backgroundColor: theme.skin === "dark" ? "#333" : "#f9f9f9",
              }}
            >
              <Menu
                style={{
                  backgroundColor: theme.skin === "dark" ? "#333" : "#f9f9f9",
                  color: theme.skin === "dark" ? "#fff" : "#333",
                }}
                menuItemStyles={{
                  button: ({ level, active, disabled }) => ({
                    color: active ? "red" : disabled ? "#5c5b57" : "#5c5b57",
                    backgroundColor: active ? "red" : undefined,
                  }),
                }}
              >
                {categories.map((category) => (
                  <SubMenu
                    key={category.id}
                    label={category.category_name}
                    icon={<Icon name="line-chart" />}
                    style={{
                      backgroundColor: theme.skin === "dark" ? "#333" : "#f9f9f9",
                      color: theme.skin === "dark" ? "#fff" : "#333",
                    }}
                  >
                    {subCategories.map((filteredSubCategory) => (
                      <MenuItem
                        className="menuitem-hover"
                        // icon={<Icon name="dot" />}
                        style={{
                          backgroundColor: theme.skin === "dark" ? "#333" : "#f9f9f9",
                          color: theme.skin === "dark" ? "#fff" : "#333",
                          textTransform: "capitalize",
                        }}
                        key={filteredSubCategory.id}
                        onClick={() => handleSubCategoryClick(category.id, filteredSubCategory.id)}
                      >
                        {filteredSubCategory.sub_category_name}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ))}
              </Menu>
            </Sidebar>
          </Box>
        </Grid>
        <Grid item xs={12} md={9.5} sx={{ zIndex: 0 }}>
          <Box p={3}>
            {activeTab === 0 && (
              <>
                {docPresent && (
                  <div className="text-center" style={{ marginTop: "4.3%", marginLeft: "-21%" }}>
                    <img src={noData} alt="No data found" height={100} width={100} />
                    <p className="mt-2 font-bold">
                      <b>Oops! It's Empty</b>
                    </p>
                    <p style={{ marginTop: "-15px" }}>No report's available for this coin.</p>
                  </div>
                )}
                {loading ? (
                  <CircularProgress
                    color="error"
                    style={{ textAlign: "center", marginLeft: "50%", marginTop: "15%" }}
                  />
                ) : doc ? (
                  <PDFFlipBook pdfUrl={doc} />
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        display: selectedCategoryId || selectedSubCategoryId ? "none" : "flex",
                        textAlign: "center",
                      }}
                    >
                      Please select a category and sub-category to view the report.
                    </Typography>
                  </div>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Reports;

import React from "react";
import home from "../src/images/home_black.png";
import { Link } from "react-router-dom";

const FixedHomeLogo = () => {
  return (
    <>
      <Link to={"https://silocloud.io/"}>
        <img src={home} alt="Fixed" className="fixed-image" />
      </Link>
    </>
  );
};

export default FixedHomeLogo;

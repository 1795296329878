import React from "react";
import Head from "../../layout/head/Head";
import Header from "../Details/pages/Details/components/Header";
import Paypal from "./components/Paypal";
import PortfolioDetails from "./components/PortfolioDetails";
import UserPortfolio from "./components/UserPortfolio";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <>
      <Head title={"Portfolio"} />
      <section style={{ marginTop: "30px", display: "flex" }}>
        <div className="p-3 border bg-white my-2 mt-2" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <nav>
            <ul className="breadcrumb breadcrumb-arrow">
              <li className="breadcrumb-item">
                <Link to={"/"}>companies</Link>
              </li>
              <li className="breadcrumb-item active">portfolio</li>
            </ul>
          </nav>
          {/* <Header title="Portfolio" /> */}
          {/* <PortfolioDetails /> */}
          {/* <div style={{ flex: 1, display: "flex" }}> */}
          <UserPortfolio />
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Portfolio;

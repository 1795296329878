import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Pegasus from "../../assets/coins/Pegasus Fund Orange.png";
import EOC from "../../assets/coins/EOC.png";
import Enum from "../../assets/coins/Enum.png";
import stocks from "../../assets/stocks.png";
import Inland from "../../assets/coins/Inland.png";

import { Eye } from "lucide-react";
import { Button, ReactDataTable } from "../../components/Component";
import TempTable from "../../tempData";
import Head from "../../layout/head/Head";

const MarketCapDetails = () => {
  const params = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [tableId, setTableId] = useState();

  useEffect(() => {
    if (params.pathname === "/trending") {
      // setTitle("What Are The Trending Companies?");
      // setContent("Listed below are the hottest trending Companies on CoinExchange.");
      setTableId(1);
    }
    if (params.pathname === "/recently-added") {
      // setTitle("New Companies");
      // setContent("New Companies added to CoinExchange in the last 30 days");
      setTableId(2);
    }
    if (params.pathname === "/most-visited") {
      // setTitle("What Are The Most Viewed Companies on CoinExchange?");
      // setContent("Listed below are the most viewed Companies, based on internal CoinExchange real-time data.");
      setTableId(3);
    }
  }, [params]);

  return (
    <>
      {tableId === 1 && <Head title={"Trending"} />}
      {tableId === 2 && <Head title={"Recently Added"} />}
      {tableId === 3 && <Head title={"Most Visited"} />}
      <section className={`mt-4 pt-4`} style={{ marginTop: "3%" }}>
        <div className="m-3 mb-4 pt-4 mt-4">
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
        <div className="bg-white p-3 border mt-4">
          <nav className="mb-3">
            <ul class="breadcrumb breadcrumb-arrow">
              <li class="breadcrumb-item">
                <Link to={"/"}>companies</Link>
              </li>
              <li class="breadcrumb-item active">
                {tableId === 1 && "Trending"}
                {tableId === 2 && "Recently Added"}
                {tableId === 3 && "Most Visited"}
              </li>
            </ul>
          </nav>
          <div className="mb-4">
            <Button
              className={`btn-sm ${tableId === 1 ? "btn-dim" : ""}`}
              color={`${tableId === 1 ? "primary" : ""}`}
              onClick={() => navigate("/trending")}
            >
              Trending
            </Button>
            <Button
              className={`btn-sm ${tableId === 3 ? "btn-dim" : ""}`}
              color={`${tableId === 3 ? "primary" : ""}`}
              onClick={() => navigate("/most-visited")}
            >
              Most Visited
            </Button>
            <Button
              className={`btn-sm ${tableId === 2 ? "btn-dim" : ""}`}
              color={`${tableId === 2 ? "primary" : ""}`}
              onClick={() => navigate("/recently-added")}
            >
              Recently Added
            </Button>
          </div>
          <TempTable />
        </div>
      </section>
    </>
  );
};
export default MarketCapDetails;

import { useParams } from "react-router";
import { getRequest } from "../../../../../api-service";
import { useContext, useEffect, useState } from "react";
import { Grid, Skeleton, useMediaQuery } from "@mui/material";
import { ReviewContext } from "../../../../../App";

const InfoOfUpAndDowns = ({ coinDetails }) => {
  const { coinId } = useParams();
  const { reviewSubmitted } = useContext(ReviewContext);

  const [upAndDowns, setUpAndDowns] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const fetchData = async () => {
    const result = await getRequest(`/coin/get-coin?coin_id=${coinId}`).then((res) => {
      setLoading(false);
      setUpAndDowns(res?.data?.coin[0]);
    });
  };

  // console.log("upAndDowns", upAndDowns);
  useEffect(() => {
    fetchData();
  }, [coinDetails, reviewSubmitted]);

  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  return (
    <>
      {/* <h5 className="px-1 mt-4">Minority Startups (MST) price has declined today.</h5> */}
      <h2
        style={{
          fontSize: "22px",
          fontWeight: "600",
        }}
        className="px-1 mt-4"
      >
        Key stats
      </h2>

      {loading ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"80%"} />
        </>
      ) : (
        <>
          <Grid container spacing={2} sx={{ margin: isSmallScreen ? "" : "0 0%", mb: 2 }}>
            <Grid item xs={6} sm={6} md={4}>
              <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                <p className="stats-head">Total Investment</p>
                <p className="stats-subhead">{formatNumber(upAndDowns.total_investment)} USD</p>
              </div>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                <p className="stats-head">Price</p>
                <p className="stats-subhead">{formatNumber(upAndDowns.price)} USD</p>
              </div>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                <p className="stats-head">Market cap</p>
                <p className="stats-subhead">{formatNumber(upAndDowns.market_cap)} USD</p>
              </div>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                <p className="stats-head mt-2">Circulating Supply</p>
                <p className="stats-subhead">{formatNumber(upAndDowns.circulation_supply)} USD</p>
              </div>
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                <p className="stats-head mt-2">Volume</p>
                <p className="stats-subhead">{formatNumber(upAndDowns.volume)} USD</p>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default InfoOfUpAndDowns;

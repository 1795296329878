import React, { useRef } from "react";
import { Box, Card, CardContent, Typography, CardMedia, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pegasus from "../../../../../assets/coins/Pegasus Fund Orange.png";
import EOC from "../../../../../assets/coins/EOC.png";
import Enum from "../../../../../assets/coins/Enum.png";
import Inland from "../../../../../assets/coins/Inland.png";
import { useTheme } from "../../../../../layout/provider/Theme";
const relatedCoins = [
  { name: "Pegasus Fund", price: "$0.73", image: Pegasus, link: "/companies/1", symbol: "PGSF" },
  { name: "EOC Group", price: "$0.73", image: EOC, link: "/companies/5", symbol: "EOF" },
  { name: "Enumium", price: "$0.73", image: Enum, link: "/companies/6", symbol: "ENU" },
  { name: "Inland Micro Fuels", price: "$0.73", image: Inland, link: "/companies/7", symbol: "IMiF" },
  { name: "Pegasus Fund", price: "$0.73", image: Pegasus, link: "/companies/1", symbol: "PGSF" },
  { name: "EOC Group", price: "$0.73", image: EOC, link: "/companies/5", symbol: "EOF" },
  { name: "Enumium", price: "$0.73", image: Enum, link: "/companies/6", symbol: "ENU" },
  { name: "Inland Micro Fuels", price: "$0.73", image: Inland, link: "/companies/7", symbol: "IMiF" },
  // Add more coins here
];

const RelatedCoins = () => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const theme = useTheme();
  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <>
      <h2
        style={{
          fontSize: "22px",
          fontWeight: "600",
          marginTop: "5%",
        }}
        className="px-1 pt-4 mt-4"
      >
        Related Coins You May Like
      </h2>
      <Box sx={{ my: 0, p: 1, minWidth: "300px", position: "relative", pr: 5, pl: 5 }}>
        <Box className="scroll-container" sx={{ display: "flex", overflowX: "auto", pt: 2, pb: 1 }} ref={scrollRef}>
          {relatedCoins.map((coin, index) => (
            <Card
              className={`card-related-coin ${theme.skin === "dark" ? "dark-modeMUI" : ""}`}
              key={index}
              sx={{
                minWidth: "302px",
                mx: 1,
                cursor: "pointer",
                boxShadow: "none",
                border: "1px solid #e0e0e0",
                borderRadius: "16px",
              }}
              onClick={() => navigate(coin.link)}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CardMedia component="img" sx={{ width: 45, height: 45, mr: 2 }} image={coin.image} alt={coin.name} />
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "16px" }} noWrap>
                      {coin.name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "14px", display: "block", mt: -0.5, color: "#6A6D78" }}>
                      {coin.symbol}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h5" sx={{ fontSize: "16px", mt: 2, fontWeight: "500" }} noWrap>
                  {coin.price} USD
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
        <IconButton
          onClick={() => scroll(-250)}
          sx={{
            position: "absolute",
            top: "53%",
            left: 0,
            transform: "translateY(-50%)",
            color: theme.skin === "dark" ? "#fff" : "#000",

            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
            // zIndex: 1,
            "&:hover": {
              backgroundColor: "#99999",
            },
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={() => scroll(250)}
          sx={{
            position: "absolute",
            top: "53%",
            right: 0,
            transform: "translateY(-50%)",
            color: theme.skin === "dark" ? "#fff" : "#000",

            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",
            // zIndex: 1,
            "&:hover": {
              backgroundColor: "#99999",
            },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default RelatedCoins;

import { getRequest } from "../../../api-service";
import { useEffect, useState } from "react";
import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "../../../layout/provider/Theme";
import AvgReturn from "../../../images/Avrage Return.png";
import totalInvest from "../../../images/Total investment .png";
import totalReturn from "../../../images/Total Return .png";
import activeComp from "../../../images/Active Companies .png";

const formatNumber = (num) => {
  return num.toLocaleString();
};

export const InfoCard = ({ price, heading, icon }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <p className="m-0 bg-primary-dim p-2 rounded-pill me-2 text-primary">{icon}</p>
      <div className="ms-1">
        <h6 className="m-0 p-0 mt-1">{price}</h6>
        <p className="m-0 p-0">{heading}</p>
      </div>
    </div>
  );
};

const PriceOverview = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(min-width:1261px)");
  const isSmallScreen1 = useMediaQuery("(max-width:600px)");

  const [dashboardValues, setDashboardValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getDashboardValues = () => {
    getRequest(`/coin/dashboard`)
      .then((response) => {
        setDashboardValues(response.data.section_first[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching dashboard values:", error);
      });
  };

  useEffect(() => {
    getDashboardValues();
  }, []);

  const CardComponent = ({ title, icon, price, ml }) => {
    return (
      <Card
        sx={{
          border: "none",
          mt: 1,
          ml: isSmallScreen1 ? ml : 0,
          mr: isSmallScreen1 ? -ml : 0,
          minWidth: "100px",
          height: "150px",
          width: "250px",
          color: theme.skin === "dark" ? "#fff" : "#000",
          backgroundColor: theme.skin === "dark" ? "#161616" : "#fff",
        }}
      >
        <CardContent sx={{ textAlign: "center", display: "block", justifyContent: "center" }}>
          {isLoading ? (
            <Skeleton sx={{ borderRadius: "24px" }} variant="rounded" width={"100%"} height={"45px"} />
          ) : (
            <p className="m-0 bg-primary-dim p-1 rounded-pill me-1 text-primary">
              <img src={icon} height={35} width={40} alt="" />
            </p>
          )}
          {isLoading ? (
            <Skeleton sx={{ fontSize: "1rem", textAlign: "center", mt: 1, ml: 4 }} variant="rounded" width={"70%"} />
          ) : (
            <Typography
              variant="p"
              sx={{ mt: 1, fontWeight: 700, display: "block", fontSize: isSmallScreen1 ? "12px" : "" }}
            >
              {title}
            </Typography>
          )}
          {isLoading ? (
            <Skeleton sx={{ fontSize: "2rem", textAlign: "center", mt: 1, ml: 5.5 }} variant="rounded" width={"60%"} />
          ) : (
            <Typography variant="h5" sx={{ mt: 1, fontWeight: 700, display: "block" }}>
              {price}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Grid container spacing={1} columnSpacing={0} sx={{ display: "flex", justifyContent: "center", margin: "auto" }}>
        <Grid
          item
          xs={6}
          sm={3}
          lg={3}
          sx={{ display: "flex", justifyContent: isSmallScreen ? "end" : "center", margin: "auto" }}
        >
          <CardComponent
            title="Total Investment"
            icon={totalInvest}
            price={`$${formatNumber(dashboardValues.total_investment || 0)}`}
            ml={-2}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3} sx={{ display: "flex", justifyContent: "center", margin: "auto" }}>
          <CardComponent
            title="Total Return"
            icon={totalReturn}
            price={`$${formatNumber(dashboardValues.total_investment + dashboardValues.total_investment / 10 || 0)}`}

            // price={`$${formatNumber(dashboardValues.total_return || 0)}`}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          lg={3}
          sx={{ display: "flex", justifyContent: isSmallScreen ? "start" : "center", margin: "auto" }}
        >
          <CardComponent
            title="Active Companies"
            icon={activeComp}
            price={`${formatNumber(dashboardValues.total_companies || 0)}`}
            ml={-2}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          lg={3}
          sx={{
            display: "flex",
            justifyContent: isSmallScreen ? "start" : "center",
            marginLeft: isSmallScreen ? "-25px" : "0px",
          }}
        >
          <CardComponent
            title="Average Return"
            icon={AvgReturn}
            // price={`${formatNumber(dashboardValues.average_return || 0)}`}
            price={`${formatNumber(16.2)}%`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PriceOverview;

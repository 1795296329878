import React, { useState } from "react";
import { List, ListItem, ListItemText, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";

const PortfolioSidebar = ({ onSelect }) => {
  const theme = useTheme();
const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Dashboard");

  const handleDrawerToggle = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleSelect = (tab) => {
    setActiveTab(tab);
    onSelect(tab);
  };

  const drawerContent = (
    <List>
      <ListItem
        button
        onClick={() => handleSelect("Dashboard")}
        className="portfolio-sidebar-menus"
        sx={{
          backgroundColor: activeTab === "Dashboard" ? "#fff1f1" : "transparent",
          color: activeTab === "Dashboard" ? "red" : theme.skin === "dark" ? "#fff" : "#000",
        }}
      >
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleSelect("Holdings")}
        className="portfolio-sidebar-menus"
        sx={{
          backgroundColor: activeTab === "Holdings" ? "#fff1f1" : "transparent",
          color: activeTab === "Holdings" ? "red" : theme.skin === "dark" ? "#fff" : "#000",
        }}
      >
        <ListItemText primary="Holdings" />
      </ListItem>
    </List>
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ mt: 3, mb: 0 }}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            // sx={{ position: "relative", top: "20px", left: 0 }}
          >
            {drawerContent}
          </Drawer>
        </>
      ) : (
        drawerContent
      )}
    </>
  );
};

export default PortfolioSidebar;

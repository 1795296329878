import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Paper,
  useMediaQuery,
  TableContainer,
  TableBody,
  Table,
  TableCell,
  Skeleton,
  TableRow,
} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import walletIcon from "../../../../images/walletIcon.png";
import walletIconw from "../../../../images/walletw.png";
import { DeleteOutline } from "@mui/icons-material";
import deposit from "../../../../images/deposit.png";
import withdraw from "../../../../images/withdraw.png";
import transfer from "../../../../images/transfer.png";
import transferw from "../../../../images/transferw.png";
import withdraww from "../../../../images/withdraww.png";
import depositw from "../../../../images/depositw (1).png";
import { useTheme } from "../../../../layout/provider/Theme";
import { getRequest } from "../../../../api-service";

const Dashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [data, setCoinData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchText1, setSearchText1] = useState("");

  const getCoinsData = () => {
    getRequest(`/coin/get-coin?search_keyword=${searchText}`).then((res) => {
      setCoinData(res?.data?.coin || []); // Ensure data is always an array
    });
  };

  useEffect(() => {
    getCoinsData();
  }, [searchText, searchText1]);

  const handleOptionSelected = (event, value) => {
    setSearchText(""); // Reset the text field

    if (value) {
      // addToPortfolioHandler(value.id);
      setSearchText(""); // Reset the text field
      setSearchText1(""); // Reset the text field
    }
  };

  const PaperComponent = (props) => (
    <Paper
      {...props}
      sx={{
        backgroundColor: theme.skin === "dark" ? "rgba(40, 47, 56 ,0.8)" : "rgba(255, 255, 255 ,0.9)",
        border: "1px solid #D0DEFF",
        borderRadius: "5px",
        color: theme.skin === "dark" ? "#fff" : "#000",
      }}
    />
  );

  return (
    <>
      <div>
        <Card
          className="wallet-card mb-4"
          sx={{
            color: theme.skin === "dark" ? "#fff" : "#000",
            backgroundColor: theme.skin === "dark" ? "#000" : "#fff",

            width: isSmallScreen ? "265px" : "300px",
            mb: 2,
          }}
        >
          <CardContent>
            {/* <AccountBalanceWalletIcon style={{ fontSize: 40 }} /> */}
            <img src={theme.skin === "dark" ? walletIconw : walletIcon} alt="" height={38} width={38} />
            <Typography variant="h6" className="w-balance" sx={{ color: " #ff2727" }}>
              <span style={{ color: "#ff2727" }}>Wallet Balance</span>
            </Typography>
            <Typography variant="h6" className="w-price">
              111111.780 SBC
            </Typography>
          </CardContent>
        </Card>
      </div>

      <div
        // className="mt-4 mb-4"
        style={{
          textAlign: "center",
          marginTop: isSmallScreen ? "10px" : "40px",
          display: "flex",
          gap: 10,
          justifyContent: isSmallScreen ? "center" : "",
        }}
      >
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="outlined"
              className="wallet-button deposit-h"
              style={{ width: isSmallScreen ? "fit-content" : "", padding: isSmallScreen ? "10px" : "" }}
            >
              <img
                src={theme.skin === "dark" ? depositw : deposit}
                alt=""
                height={20}
                width={20}
                // style={{ display: isSmallScreen ? "none" : "" }}
              />
              <span style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>Deposit </span>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="outlined"
              className="wallet-button withdraw-h"
              style={{ width: isSmallScreen ? "fit-content" : "", padding: isSmallScreen ? "10px" : "" }}
            >
              <img
                src={theme.skin === "dark" ? withdraww : withdraw}
                alt=""
                height={20}
                width={20}
                // style={{ display: isSmallScreen ? "none" : "" }}
              />
              <span style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>Withdraw </span>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="outlined"
              className="wallet-button transfer-h"
              style={{ width: isSmallScreen ? "fit-content" : "", padding: isSmallScreen ? "10px" : "" }}
            >
              <img
                src={theme.skin === "dark" ? transferw : transfer}
                alt=""
                height={20}
                width={20}
                // style={{ display: isSmallScreen ? "none" : "" }}
              />
              <span style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>Transfer </span>
            </Button>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ gap: 1 }}>
            <div style={{ display: "block", width: "100%", fontSize: "14px", fontWeight: "600" }}>Trading Wallet</div>

            <Grid container spacing={1} rowSpacing={2} textAlign={"center"} sx={{ mt: 0.1 }}>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  // className={theme.skin === "dark" ? "homepage-searchBarDark" : "homepage-searchBar"}
                  fullWidth
                  id="combo-box-demo"
                  options={data || []} // Ensure options is always an array
                  getOptionLabel={(option) => option?.coin_symbol}
                  onChange={handleOptionSelected}
                  inputValue={searchText}
                  onInputChange={(event, newInputValue) => {
                    setSearchText(newInputValue);
                  }}
                  PaperComponent={PaperComponent}
                  sx={{
                    // width: 200,
                    borderRadius: "5px",
                    color: theme.skin === "dark" ? "#fff" : "#000",
                  }}
                  renderInput={(params) => (
                    <TextField
                      // variant="filled"
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        style: {
                          color: theme.skin === "dark" ? "#fff" : "#000",
                          fontSize: "0.9rem", // Adjust the label font size as needed
                          color: "grey", // Label color
                          // marginTop: "-3%",
                        },
                      }}
                      label="Choose Coin"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px", // Set height of the input element
                          display: "flex",
                          alignItems: "center", // Center the text vertically
                        },
                        // "& .MuiInputLabel-root.Mui-focused": {
                        //   // display: "none",
                        //   top: "3%",
                        //   fontSize: "0.9rem", // Adjust the label font size as needed

                        //   transform: "translateY(0)",
                        // },
                        borderRadius: "5px",
                        // width: "160px",
                        color: theme.skin === "dark" ? "#fff" : "#000",
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className="homepage-searchBar-option d-flex"
                      style={{
                        color: theme.skin === "dark" ? "#fff" : "#000",
                      }}
                    >
                      <img src={option.coin_logo} alt="" height={30} width={30} style={{ marginRight: "10px" }} />
                      <p> {option.coin_symbol}</p>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  // variant="filled"
                  PaperComponent={PaperComponent}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px", // Set height of the input element
                    },
                    // width: 300,
                    borderRadius: "5px",
                    // marginRight: "20px",
                    "& .MuiInputLabel-root.Mui-focused": {
                      // display: "none",
                    },
                  }}
                  // className="wallet-id"
                  label="Wallet ID"
                  InputLabelProps={{
                    style: {
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      fontSize: "0.9rem", // Adjust the label font size as needed
                      color: "grey", // Label color
                      marginTop: "-6px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="outlined" className="wallet-button save-h" style={{ height: "40px", width: "60px" }}>
                  <span style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>Save</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ gap: 1 }}>
            <div style={{ display: "block", width: "100%", fontSize: "14px", fontWeight: "600" }}>Funding Wallet</div>

            <Grid container spacing={1} rowSpacing={2} textAlign={"center"} sx={{ mt: 0.1 }}>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  // className={theme.skin === "dark" ? "homepage-searchBarDark" : "homepage-searchBar"}
                  fullWidth
                  id="combo-box-demo"
                  options={data || []} // Ensure options is always an array
                  getOptionLabel={(option) => option?.coin_symbol}
                  onChange={handleOptionSelected}
                  inputValue={searchText1}
                  onInputChange={(event, newInputValue) => {
                    setSearchText1(newInputValue);
                  }}
                  PaperComponent={PaperComponent}
                  sx={{
                    // width: 200,
                    borderRadius: "5px",
                    color: theme.skin === "dark" ? "#fff" : "#000",
                  }}
                  renderInput={(params) => (
                    <TextField
                      // variant="filled"
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        style: {
                          color: theme.skin === "dark" ? "#fff" : "#000",
                          fontSize: "0.9rem", // Adjust the label font size as needed
                          color: "grey", // Label color
                          // marginTop: "-3%",
                        },
                      }}
                      label="Choose Coin"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "40px", // Set height of the input element
                          display: "flex",
                          alignItems: "center", // Center the text vertically
                        },
                        // "& .MuiInputLabel-root.Mui-focused": {
                        //   // display: "none",
                        //   top: "3%",
                        //   fontSize: "0.9rem", // Adjust the label font size as needed

                        //   transform: "translateY(0)",
                        // },
                        borderRadius: "5px",
                        // width: "160px",
                        color: theme.skin === "dark" ? "#fff" : "#000",
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className="homepage-searchBar-option d-flex"
                      style={{
                        color: theme.skin === "dark" ? "#fff" : "#000",
                      }}
                    >
                      <img src={option.coin_logo} alt="" height={30} width={30} style={{ marginRight: "10px" }} />
                      <p> {option.coin_symbol}</p>
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  // variant="filled"
                  PaperComponent={PaperComponent}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px", // Set height of the input element
                    },
                    // width: 300,
                    borderRadius: "5px",
                    // marginRight: "20px",
                    "& .MuiInputLabel-root.Mui-focused": {
                      // display: "none",
                    },
                  }}
                  // className="wallet-id"
                  label="Wallet ID"
                  InputLabelProps={{
                    style: {
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      fontSize: "0.9rem", // Adjust the label font size as needed
                      color: "grey", // Label color
                      marginTop: "-6px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="outlined" className="wallet-button save-h" style={{ height: "40px", width: "60px" }}>
                  <span style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>Save</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* <div className="d-flex mt-4 justify-between"> */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={2.12}>
          <Card
            className="token-cards"
            sx={{
              mt: isSmallScreen ? 0 : 4,
              color: theme.skin === "dark" ? "#fff" : "#000",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" className="card-token-price">
                Live Token Price
              </Typography>
              <Typography variant="body2" className="card-token-value">
                0.000431
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.12}>
          <Card
            className="token-cards"
            sx={{
              mt: isSmallScreen ? 0 : 4,
              color: theme.skin === "dark" ? "#fff" : "#000",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" className="card-token-price">
                Network Commission{" "}
              </Typography>
              <Typography variant="body2" className="card-token-value">
                0.233{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.12}>
          <Card
            className="token-cards"
            sx={{
              mt: isSmallScreen ? 0 : 4,
              color: theme.skin === "dark" ? "#fff" : "#000",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" className="card-token-price">
                Social Network Points{" "}
              </Typography>
              <Typography variant="body2" className="card-token-value">
                7.94{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.12}>
          <Card
            className="token-cards"
            sx={{
              mt: isSmallScreen ? 0 : 4,
              color: theme.skin === "dark" ? "#fff" : "#000",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" className="card-token-price">
                User’s Token Value{" "}
              </Typography>
              <Typography variant="body2" className="card-token-value">
                $50{" "}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3.52}>
          <TableContainer sx={{ color: theme.skin === "dark" ? "#fff" : "#000" }} className="portfolio-card-table">
            <Table size="small" aria-label="simple table" sx={{ mt: 4 }}>
              <TableBody>
                <TableRow sx={{ borderBottom: "1px solid #D0DEFF" }}>
                  <TableCell style={{ width: "25%", borderBottom: "1px solid #D0DEFF" }}>
                    <b style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>24Hr Price</b>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "25%",
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      borderBottom: "1px solid #D0DEFF",
                    }}
                  >
                    <p>0.00%</p>{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      width: "25%",
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      borderBottom: "1px solid #D0DEFF",
                    }}
                  >
                    <b>24Hr Volume</b>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "25%",
                      color: theme.skin === "dark" ? "#fff" : "#000",
                      borderBottom: "1px solid #D0DEFF",
                    }}
                  >
                    <p>24,098.22 SBC</p>{" "}
                  </TableCell>
                </TableRow>

                <TableRow sx={{ borderBottom: "none" }}>
                  <TableCell
                    style={{ width: "25%", color: theme.skin === "dark" ? "#fff" : "#000", borderBottom: "none" }}
                  >
                    <b>Active Traders</b>
                  </TableCell>
                  <TableCell
                    style={{ width: "25%", color: theme.skin === "dark" ? "#fff" : "#000", borderBottom: "none" }}
                  >
                    <p>3</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* </div> */}
    </>
  );
};

export default Dashboard;

import { Button, Col, Row } from "reactstrap";
import coin from "../../../assets/coin.jpg";
import Minority from "../../../assets/coins/Minority.png";
import NoitBlack from "../../../assets/coins/NoitBlack.png";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Clock4, Eye, ImageIcon, Star } from "lucide-react";
import styles from "./MarketCap.module.css";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { getRequest, postRequest } from "../../../api-service";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "../../../layout/provider/Theme";

const VIDEO_PATH = "https://api.silocloud.io/api/v1/public/channel-video";

const MarketCap = () => {
  const navigate = useNavigate();

  const theme = useTheme();

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [page, setPage] = useState(0);

  const [playerWidth, setPlayerWidth] = useState(500);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newWidth = screenWidth < 500 ? screenWidth - 24 : 500; // Adjusted width
      setPlayerWidth(newWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [coins1, setCoins1] = useState([]);
  const [coins2, setCoins2] = useState([]);
  const [coins3, setCoins3] = useState([]);

  // console.log("coins", coins);

  useEffect(() => {
    getCoinsUpdatedata();
  }, [page]);

  const getCoinsUpdatedata = () => {
    const payload = {
      id: page,
    };

    getRequest("/coin/trending-coin")
      .then((res) => {
        setCoins1(res.data.trending_coins);
        setCoins2(res.data.recently_added_coins);
        setCoins3(res.data.most_viewed_coins);
        setLoading(false);
        // console.log("rescoins", res);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching coins:", error);
      });
  };

  const handleRowClick = (coinId) => {
    navigate(`/companies/${coinId}`);

    const payload = {
      coin_id: coinId,
    };

    postRequest("/coin/increment-view", payload).then((response) => {
      console.log("response", response);
    });
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setCoins((prevCoins) => shuffleCoins(prevCoins));
  //     setPage((page) => (page + 1) % 3);
  //   }, 50000);
  //   return () => clearInterval(interval);
  // }, []);

  const handelRedirection = (page) => {
    // navigate("/trending");
    if (page === 0) navigate("/trending");
    if (page === 1) navigate("/recently-added");
    if (page === 2) navigate("/most-visited");
  };

  const addToPortfolioHandler = () => {
    Swal.fire({
      title: "Coin added to portfolio",
      text: "Coin has been added to your account, complete your payment in the portfolio section.",
      icon: "success",
    });
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);

  //   // Cleanup the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      {loading ? (
        <>
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2 mb-2" style={{ padding: "15px 20px" }}>
                  <Typography variant="h6">🔥 Trending</Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px" }}
                  >
                    View All
                  </Typography>
                </div>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="d-flex p-1 mt-2 cursor mb-2" style={{ cursor: "pointer" }}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <div>
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={110} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                    </div>
                    <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-upword-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-downward-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                    </div>
                  </div>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2" style={{ padding: "15px 20px" }}>
                  <Typography variant="h6">👁️ Most Visited</Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px" }}
                    onClick={handelRedirection}
                  >
                    View All
                  </Typography>
                </div>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="d-flex p-1 mt-2 cursor" style={{ cursor: "pointer" }}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <div>
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={110} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                    </div>
                    <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-upword-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-downward-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                    </div>
                  </div>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2" style={{ padding: "15px 20px" }}>
                  <Typography variant="h6">🕰️ Recently Added</Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px" }}
                    onClick={handelRedirection}
                  >
                    View All
                  </Typography>
                </div>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="d-flex p-1 mt-2 cursor" style={{ cursor: "pointer" }}>
                    <Skeleton variant="circular" width={50} height={50} />
                    <div>
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={110} />
                      <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                    </div>
                    <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-upword-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                      <ListItem className="" style={{ color: "#e1e4e8", marginLeft: "" }}>
                        <em className="icon ni ni-downward-alt-fill"></em>
                        <Skeleton variant="text" sx={{ fontSize: "1rem", ml: 1 }} width={70} />
                      </ListItem>
                    </div>
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          // style={{ border: "3px solid red" }}
          className={`d-flex justify-content-center align-items-start ${styles.responsiveDiv}`}
        >
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2" style={{ padding: "0 20px" }}>
                  <Typography variant="h6">🔥 Trending</Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px", mt: 1.5 }}
                    onClick={() => handelRedirection(0)}
                  >
                    View All
                  </Typography>
                </div>
                {coins1?.slice(0, 3)?.map((item, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between p-1 mt-2 cursor"
                      onClick={() => handleRowClick(item.coin_id ? item.coin_id : item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem sx={{ minWidth: isSmallScreen ? "100px" : "227px", mb: -2 }}>
                        <ListItemAvatar>
                          <Avatar src={`https://api.silocloud.io/get-file-content/public/${item.coin_logo}`} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ fontSize: "10px" }}
                          primary={item.coin_name}
                          secondary={<p style={{ color: "#999999" }}>{item.coin_symbol}</p>}
                        />
                      </ListItem>

                      <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                        <ListItem
                          className=""
                          style={{ color: "green", marginLeft: "-25px", display: item.id % 3 === 0 ? "none" : "flex" }}
                        >
                          <em className="icon ni ni-upword-alt-fill"></em>
                          {/* {item.value1}% */}
                          {5.1}%
                        </ListItem>
                        <ListItem
                          className=""
                          style={{ color: "red", marginLeft: "-20px", display: item.id % 3 === 0 ? "flex" : "none" }}
                        >
                          <em className="icon ni ni-downward-alt-fill"></em>
                          {4.9}%
                        </ListItem>
                      </div>

                      {/* <p>
                        <ListItemText primary={item.investment_amount} secondary={item.coin_symbol} />
                      </p> */}
                    </div>
                  </>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2" style={{ padding: "0 20px" }}>
                  <Typography variant="h6">👁️ Most Visited</Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px", mt: 1.5 }}
                    onClick={() => handelRedirection(2)}
                  >
                    View All
                  </Typography>
                </div>
                {coins3?.slice(0, 3)?.map((item, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between p-1 mt-2 cursor"
                      onClick={() => handleRowClick(item.coin_id ? item.coin_id : item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem sx={{ minWidth: isSmallScreen ? "100px" : "227px", mb: -2 }}>
                        <ListItemAvatar>
                          <Avatar src={`https://api.silocloud.io/get-file-content/public/${item.coin_logo}`} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ fontSize: "10px" }}
                          primary={item.coin_name}
                          secondary={<p style={{ color: "#999999" }}>{item.coin_symbol}</p>}
                        />
                      </ListItem>

                      <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                        <ListItem
                          className=""
                          style={{ color: "green", marginLeft: "-25px", display: item.id % 3 === 0 ? "none" : "flex" }}
                        >
                          <em className="icon ni ni-upword-alt-fill"></em>
                          {/* {item.value1}% */}
                          {3.1}%
                        </ListItem>
                        <ListItem
                          className=""
                          style={{ color: "red", marginLeft: "-20px", display: item.id % 3 === 0 ? "flex" : "none" }}
                        >
                          <em className="icon ni ni-downward-alt-fill"></em>
                          {/* {item.value2}% */}
                          {3.7}%
                        </ListItem>
                      </div>

                      {/* <p>
                        <ListItemText primary={item.investment_amount} secondary={item.coin_symbol} />
                      </p> */}
                    </div>
                  </>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  borderRadius: "1rem",
                  mt: 6,
                  pb: 2,
                  minHeight: "335px",
                  color: theme.skin === "dark" ? "#fff" : "#000",
                  backgroundColor: theme.skin === "dark" ? "#161616 " : "#fff",
                }}
              >
                <div className="d-flex justify-content-between mt-2" style={{ padding: "0 20px" }}>
                  <Typography variant="h6">🕰️ Recently Added </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#aeb5b0", cursor: "pointer", fontSize: "10px", mt: 1.5 }}
                    onClick={() => handelRedirection(1)}
                  >
                    View All
                  </Typography>
                </div>
                {coins2?.slice(0, 3)?.map((item, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between p-1 mt-2 cursor"
                      onClick={() => handleRowClick(item.coin_id ? item.coin_id : item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem sx={{ minWidth: isSmallScreen ? "100px" : "227px", mb: -2 }}>
                        <ListItemAvatar>
                          <Avatar src={`https://api.silocloud.io/get-file-content/public/${item.coin_logo}`} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ fontSize: "10px" }}
                          primary={item.coin_name}
                          secondary={<p style={{ color: "#999999" }}>{item.coin_symbol}</p>}
                        />
                      </ListItem>

                      <div className={`${isSmallScreen ? "d-block" : "d-flex"}`}>
                        <ListItem
                          className=""
                          style={{ color: "green", marginLeft: "-25px", display: item.id % 3 === 0 ? "none" : "flex" }}
                        >
                          <em className="icon ni ni-upword-alt-fill"></em>
                          {/* {item.value1}% */}
                          {4.2}%
                        </ListItem>
                        <ListItem
                          className=""
                          style={{ color: "red", marginLeft: "-20px", display: item.id % 3 === 0 ? "flex" : "none" }}
                        >
                          <em className="icon ni ni-downward-alt-fill"></em>
                          {/* {item.value2}% */}
                          {5.3}%
                        </ListItem>
                      </div>

                      {/* <p>
                        <ListItemText primary={item.investment_amount} secondary={item.coin_symbol} />
                      </p> */}
                    </div>
                  </>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default MarketCap;

// UserPortfolio.js
import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PortFolioSidebar from "./PortfolioSidebar";
import Dashboard from "./portfolio/Dashboard";
import Holdings from "./portfolio/Holdings";

const UserPortfolio = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedComponent, setSelectedComponent] = useState("Dashboard");

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Dashboard":
        return <Dashboard />;
      case "Holdings":
        return <Holdings />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ minHeight: "75vh" }}>
        <Grid item xs={12} sm={4} md={2}>
          <PortFolioSidebar onSelect={setSelectedComponent} />
        </Grid>
        <Grid item xs={12} sm={8} md={10} style={{ padding: isSmallScreen ? "10px" : "40px 10px" }}>
          <div>{renderSelectedComponent()}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserPortfolio;

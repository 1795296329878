import InfoOfUpAndDowns from "./components/InfoOfUpAndDowns";
import MoreDetails from "./components/MoreDetails";
import NewsAboutCoin from "./components/NewsAboutCoin";
import ProductCard from "./components/ProductCard";
import RelatedCoins from "./components/RelatedCoins";
import SharePrices from "./components/SharePrices";

const Details = ({ coinDetails }) => {
  return (
    <>
      <ProductCard coinDetails={coinDetails} />
      {/* <MoreDetails coinDetails={coinDetails} /> */}
      <hr />
      <InfoOfUpAndDowns coinDetails={coinDetails} />
      <RelatedCoins />
      {/* <NewsAboutCoin /> */}
      <hr />
      <SharePrices />
    </>
  );
};
export default Details;

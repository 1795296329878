import { getCookie } from "../../utils/Utils";

const baseUrl = "https://api.silocloud.io/api/v1";
let token =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTQwMTY3MjksImlhdCI6MTcxMzkzMDMyOSwianRpIjoiYWM0MWVhNzg5MmRmNjJjNzI4YjA0MWFkY2YxZTFhYmUiLCJ1c2VybmFtZSI6InhhaW8iLCJlbWFpbCI6ImhvbGljODYwMzFAZG92ZXNpbG8uY29tIiwiY2xvdWRVc2VySWQiOjMsInJvbGUiOnsiaWQiOjIsIm5hbWUiOiJBZG1pbmlzdHJhdG9yIiwia2V5IjoiYWRtaW4ifX0.OIBvVmMresJd72FFtbjkIdiu5z5wCOx9lHnQa49IAnI";

// Get API to fetch Client details =================================

export const getCoinsList = async ({ id }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      authToken: getCookie("authToken", token),
    },
  };

  let url = `${baseUrl}/coin/get-coin`;

  if (id) url = `${baseUrl}/coin/get-coin?coin_id=${id}`;

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

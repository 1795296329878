import React, { useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import PageFlip from "react-pageflip";
import { GlobalWorkerOptions } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs"; // Ensure the correct import for worker
// import "./PDFFlipBook.css"; // Import CSS file for styling

GlobalWorkerOptions.workerSrc = `https://api.silocloud.io/pdfworker/pdf.worker.min.js`;

const PDFFlipBook = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const pageFlipRef = useRef(null);
  const [show, setShow] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const handlePrevious = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipPrev("top");
    }
  };

  const handleNext = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipNext("bottom");
    }
  };

  return (
    <div className="pdf-container">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} className="flipbook-wrapper">
        {numPages && (
          <>
            <PageFlip ref={pageFlipRef} width={400} height={500} flipOnTouch>
              {[...Array(numPages).keys()].map((pageIndex) => (
                <div key={pageIndex} className="demoPage">
                  <Page pageNumber={pageIndex + 1} width={400} />
                </div>
              ))}
            </PageFlip>
          </>
        )}
      </Document>
      {show && (
        <div className="button-container">
          <button className="navigation-button" onClick={handlePrevious}>
            Previous
          </button>
          <button className="navigation-button" onClick={handleNext}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default PDFFlipBook;

import { ArrowLeft } from "lucide-react";
import { useEffect } from "react";
import { Button } from "reactstrap";

const DetailedNews = ({ data, close }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month; // Prefix single digit months with a '0'
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day; // Prefix single digit days with a '0'
    }
    return `${year}-${month}-${day}`;
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h2>{data.title}</h2>

      <img
        src={data.news_img}
        alt=""
        className="mt-2"
        style={{ maxHeight: "400px", width: "fit-content", objectFit: "cover", textAlign: "center" }}
      />

      <p className="text-end mt-2">{formatDate(data.updated_at)}</p>
      <p className="mt-2">{data.description}</p>

      <div className="d-flex justify-content-end align-items-center mt-2">
        <Button color="primary" className="btn-dim" outline onClick={close}>
          <ArrowLeft width={20} className="me-2" />
          <span>Back</span>
        </Button>
      </div>
    </div>
  );
};
export default DetailedNews;
